'use strict'

import 'typeface-montserrat'
import 'typeface-open-sans'
import React from 'react';
import { FilterProvider } from './src/context/FilterContext'

require('./src/assets/scss/App.scss')

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const wrapRootElement = ({ element }) => (
  <FilterProvider>{element}</FilterProvider>
);
